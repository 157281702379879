$intro_path: '/design/qr_menu/fonts/Intro';

@font-face {
    font-family: 'Intro Bold Alt';
    src: url('#{$intro_path}/Intro-Bold-Alt.eot');
    src: url('#{$intro_path}/Intro-Bold-Alt.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Bold-Alt.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Bold-Alt.woff') format('woff'),
    url('#{$intro_path}/Intro-Bold-Alt.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Bold-Alt.svg#Intro-Bold-Alt') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Book';
    src: url('#{$intro_path}/Intro-Book-Italic.eot');
    src: url('#{$intro_path}/Intro-Book-Italic.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Book-Italic.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Book-Italic.woff') format('woff'),
    url('#{$intro_path}/Intro-Book-Italic.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Book-Italic.svg#Intro-Book-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Thin Alt';
    src: url('#{$intro_path}/Intro-Thin-Alt.eot');
    src: url('#{$intro_path}/Intro-Thin-Alt.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Thin-Alt.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Thin-Alt.woff') format('woff'),
    url('#{$intro_path}/Intro-Thin-Alt.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Thin-Alt.svg#Intro-Thin-Alt') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Black Caps';
    src: url('#{$intro_path}/Intro-Black-Caps.eot');
    src: url('#{$intro_path}/Intro-Black-Caps.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Black-Caps.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Black-Caps.woff') format('woff'),
    url('#{$intro_path}/Intro-Black-Caps.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Black-Caps.svg#Intro-Black-Caps') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Book Caps';
    src: url('#{$intro_path}/Intro-Book-Caps.eot');
    src: url('#{$intro_path}/Intro-Book-Caps.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Book-Caps.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Book-Caps.woff') format('woff'),
    url('#{$intro_path}/Intro-Book-Caps.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Book-Caps.svg#Intro-Book-Caps') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Book';
    src: url('#{$intro_path}/Intro-Book.eot');
    src: url('#{$intro_path}/Intro-Book.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Book.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Book.woff') format('woff'),
    url('#{$intro_path}/Intro-Book.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Book.svg#Intro-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro';
    src: url('#{$intro_path}/Intro-Black-Italic.eot');
    src: url('#{$intro_path}/Intro-Black-Italic.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Black-Italic.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Black-Italic.woff') format('woff'),
    url('#{$intro_path}/Intro-Black-Italic.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Black-Italic.svg#Intro-Black-Italic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Regular Alt';
    src: url('#{$intro_path}/Intro-Regular-Alt.eot');
    src: url('#{$intro_path}/Intro-Regular-Alt.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Regular-Alt.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Regular-Alt.woff') format('woff'),
    url('#{$intro_path}/Intro-Regular-Alt.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Regular-Alt.svg#Intro-Regular-Alt') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Regular';
    src: url('#{$intro_path}/Intro-Regular-Italic.eot');
    src: url('#{$intro_path}/Intro-Regular-Italic.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Regular-Italic.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Regular-Italic.woff') format('woff'),
    url('#{$intro_path}/Intro-Regular-Italic.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Regular-Italic.svg#Intro-Regular-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Bold Caps';
    src: url('#{$intro_path}/Intro-Bold-Caps.eot');
    src: url('#{$intro_path}/Intro-Bold-Caps.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Bold-Caps.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Bold-Caps.woff') format('woff'),
    url('#{$intro_path}/Intro-Bold-Caps.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Bold-Caps.svg#Intro-Bold-Caps') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro';
    src: url('#{$intro_path}/Intro-Thin.eot');
    src: url('#{$intro_path}/Intro-Thin.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Thin.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Thin.woff') format('woff'),
    url('#{$intro_path}/Intro-Thin.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Thin.svg#Intro-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro';
    src: url('#{$intro_path}/Intro-Light-Italic.eot');
    src: url('#{$intro_path}/Intro-Light-Italic.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Light-Italic.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Light-Italic.woff') format('woff'),
    url('#{$intro_path}/Intro-Light-Italic.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Light-Italic.svg#Intro-Light-Italic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Intro';
    src: url('#{$intro_path}/Intro-Thin-Italic.eot');
    src: url('#{$intro_path}/Intro-Thin-Italic.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Thin-Italic.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Thin-Italic.woff') format('woff'),
    url('#{$intro_path}/Intro-Thin-Italic.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Thin-Italic.svg#Intro-Thin-Italic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Light Alt';
    src: url('#{$intro_path}/Intro-Light-Alt.eot');
    src: url('#{$intro_path}/Intro-Light-Alt.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Light-Alt.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Light-Alt.woff') format('woff'),
    url('#{$intro_path}/Intro-Light-Alt.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Light-Alt.svg#Intro-Light-Alt') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Regular Caps';
    src: url('#{$intro_path}/Intro-Regular-Caps.eot');
    src: url('#{$intro_path}/Intro-Regular-Caps.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Regular-Caps.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Regular-Caps.woff') format('woff'),
    url('#{$intro_path}/Intro-Regular-Caps.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Regular-Caps.svg#Intro-Regular-Caps') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro';
    src: url('#{$intro_path}/Intro-Light.eot');
    src: url('#{$intro_path}/Intro-Light.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Light.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Light.woff') format('woff'),
    url('#{$intro_path}/Intro-Light.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Light.svg#Intro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro';
    src: url('#{$intro_path}/Intro-Bold.eot');
    src: url('#{$intro_path}/Intro-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Bold.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Bold.woff') format('woff'),
    url('#{$intro_path}/Intro-Bold.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Bold.svg#Intro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro';
    src: url('#{$intro_path}/Intro-Regular.eot');
    src: url('#{$intro_path}/Intro-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Regular.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Regular.woff') format('woff'),
    url('#{$intro_path}/Intro-Regular.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Regular.svg#Intro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro';
    src: url('#{$intro_path}/Intro-Bold-Italic.eot');
    src: url('#{$intro_path}/Intro-Bold-Italic.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Bold-Italic.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Bold-Italic.woff') format('woff'),
    url('#{$intro_path}/Intro-Bold-Italic.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Bold-Italic.svg#Intro-Bold-Italic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Book Alt';
    src: url('#{$intro_path}/Intro-Book-Alt.eot');
    src: url('#{$intro_path}/Intro-Book-Alt.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Book-Alt.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Book-Alt.woff') format('woff'),
    url('#{$intro_path}/Intro-Book-Alt.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Book-Alt.svg#Intro-Book-Alt') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Black Inline';
    src: url('#{$intro_path}/Intro-Black-Inline.eot');
    src: url('#{$intro_path}/Intro-Black-Inline.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Black-Inline.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Black-Inline.woff') format('woff'),
    url('#{$intro_path}/Intro-Black-Inline.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Black-Inline.svg#Intro-Black-Inline') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Black Alt';
    src: url('#{$intro_path}/Intro-Black-Alt.eot');
    src: url('#{$intro_path}/Intro-Black-Alt.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Black-Alt.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Black-Alt.woff') format('woff'),
    url('#{$intro_path}/Intro-Black-Alt.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Black-Alt.svg#Intro-Black-Alt') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Thin Caps';
    src: url('#{$intro_path}/Intro-Thin-Caps.eot');
    src: url('#{$intro_path}/Intro-Thin-Caps.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Thin-Caps.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Thin-Caps.woff') format('woff'),
    url('#{$intro_path}/Intro-Thin-Caps.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Thin-Caps.svg#Intro-Thin-Caps') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro';
    src: url('#{$intro_path}/Intro-Black.eot');
    src: url('#{$intro_path}/Intro-Black.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Black.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Black.woff') format('woff'),
    url('#{$intro_path}/Intro-Black.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Black.svg#Intro-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Light Caps';
    src: url('#{$intro_path}/Intro-Light-Caps.eot');
    src: url('#{$intro_path}/Intro-Light-Caps.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Light-Caps.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Light-Caps.woff') format('woff'),
    url('#{$intro_path}/Intro-Light-Caps.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Light-Caps.svg#Intro-Light-Caps') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intro Black Inline Caps';
    src: url('#{$intro_path}/Intro-Black-Inline-Caps.eot');
    src: url('#{$intro_path}/Intro-Black-Inline-Caps.eot?#iefix') format('embedded-opentype'),
    url('#{$intro_path}/Intro-Black-Inline-Caps.woff2') format('woff2'),
    url('#{$intro_path}/Intro-Black-Inline-Caps.woff') format('woff'),
    url('#{$intro_path}/Intro-Black-Inline-Caps.ttf') format('truetype'),
    url('#{$intro_path}/Intro-Black-Inline-Caps.svg#Intro-Black-Inline-Caps') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

