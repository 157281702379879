$rothek_path: '/design/qr_menu/fonts/Rothek';

@font-face {
  font-family: 'Rothek';
  src: url('#{$rothek_path}/Rothek-ExtraLight.eot');
  src: url('#{$rothek_path}/Rothek-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('#{$rothek_path}/Rothek-ExtraLight.woff2') format('woff2'),
  url('#{$rothek_path}/Rothek-ExtraLight.woff') format('woff'),
  url('#{$rothek_path}/Rothek-ExtraLight.ttf') format('truetype'),
  url('#{$rothek_path}/Rothek-ExtraLight.svg#Rothek-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rothek';
  src: url('#{$rothek_path}/Rothek-Bold.eot');
  src: url('#{$rothek_path}/Rothek-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$rothek_path}/Rothek-Bold.woff2') format('woff2'),
  url('#{$rothek_path}/Rothek-Bold.woff') format('woff'),
  url('#{$rothek_path}/Rothek-Bold.ttf') format('truetype'),
  url('#{$rothek_path}/Rothek-Bold.svg#Rothek-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rothek';
  src: url('#{$rothek_path}/Rothek-BoldItalic.eot');
  src: url('#{$rothek_path}/Rothek-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('#{$rothek_path}/Rothek-BoldItalic.woff2') format('woff2'),
  url('#{$rothek_path}/Rothek-BoldItalic.woff') format('woff'),
  url('#{$rothek_path}/Rothek-BoldItalic.ttf') format('truetype'),
  url('#{$rothek_path}/Rothek-BoldItalic.svg#Rothek-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

